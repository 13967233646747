import type { EnterProps, GameMode, ISettledBet } from '../../global.d';
import type { States } from '../config';

export abstract class AbstractModule {
  public abstract gameMode: GameMode;

  public enterInitState(_prevState: States): void {}

  public exitInitState(_nextState: States): void {}

  public enterRestoreGameState(_prevState: States): void {}

  public exitRestoreGameState(_nextState: States): void {}

  public enterIntroState(_prevState: States): void {}

  public exitIntroState(_nextState: States): void {}

  public enterIdleState(_prevState: States): void {}

  public exitIdleState(_nextState: States): void {}

  public enterSpinState(_prevState: States): void {}

  public exitSpinState(_nextState: States): void {}

  public enterBeforeWinState(_prevState: States): void {}

  public exitBeforeWinState(_nextState: States): void {}

  public enterHighlightWinState(_prevState: States): void {}

  public exitHighlightWinState(_nextState: States): void {}

  public enterAfterWinState(_prevState: States): void {}

  public exitAfterWinState(_nextState: States): void {}

  public enterJingleState(_prevState: States): void {}

  public exitJingleState(_nextState: States): void {}

  public enterTransitionState(_prevState: States): void {}

  public exitTransitionState(_nextState: States): void {}

  public enterMysteryState(_prevState: States): void {}

  public exitMysteryState(_nextState: States): void {}

  public enterModule(_prevGameMode: GameMode | null, _props?: EnterProps): void {}

  public exitModule(_nextGameMode: GameMode): void {}

  public setResult(_result: ISettledBet): void {}
}
