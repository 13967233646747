import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';
import { GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { AbstractContainer } from '../components/AbstractContainer';

enum ReelAnimationTypes {
  Base = 'reelAmbient',
  FreeSpins = 'fsReelAmbient',
}

enum ReelAnimations {
  Base = 'reel_ambient',
  FreeSpins = 'animation',
}

class ReelsFrame extends AbstractContainer {
  private baseAmbient = new Spine(Loader.shared.resources[ReelAnimationTypes.Base]!.spineData!);
  private fsAmbient = new Spine(Loader.shared.resources[ReelAnimationTypes.FreeSpins]!.spineData!);

  constructor() {
    super();
    this.sortableChildren = true;
    this.init();
  }

  private init(): void {
    this.baseAmbient.name = 'baseFrameAnimation';
    this.baseAmbient.state.setAnimation(0, ReelAnimations.Base, true);
    this.baseAmbient.scale.set(1.32);
    this.fsAmbient.name = 'baseFrameAnimation';
    this.fsAmbient.state.setAnimation(0, ReelAnimations.FreeSpins, true);
    this.fsAmbient.scale.set(0.68);
    this.fsAmbient.y = -50;
    this.interactiveChildren = false;
    if (setGameMode() === GameMode.BASE_GAME) {
      this.fsAmbient.visible = false;
      this.baseAmbient.visible = true;
    } else {
      this.fsAmbient.visible = true;
      this.baseAmbient.visible = false;
    }
    this.addChild(this.baseAmbient, this.fsAmbient);
  }

  protected override onGameModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges(settings.mode);
        break;
      case GameMode.FREE_SPINS:
        this.handleBaseModeChanges(settings.mode);
        break;
      default:
        this.handleBaseModeChanges(settings.mode);
        break;
    }
  }

  public handleBaseModeChanges(mode: GameMode): void {
    if (mode === GameMode.FREE_SPINS) {
      this.baseAmbient.visible = false;
      this.fsAmbient.visible = true;
      this.fsAmbient.state.setAnimation(0, ReelAnimations.FreeSpins, true);
    } else {
      this.baseAmbient.visible = true;
      this.fsAmbient.visible = false;
      this.baseAmbient.state.setAnimation(0, ReelAnimations.Base, true);
    }
  }
}

export default ReelsFrame;
