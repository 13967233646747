import React, { useEffect, useRef } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { Flow } from '../../flow';
import { setIsProceedToGame } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { slotConfigGql } from '../../gql/query';
import Events from '../Events/index';
import Menu from '../Menu';
import AutoPlaySettingsMenu from '../UI/AutoPlaySettings/AutoPlaySettingsMenu';
import InfoPopup from '../UI/Info/InfoPopup';
import Spin from '../UI/SpinButton';
import styles from './screen.module.scss';

(window as unknown as { PIXI: unknown }).PIXI = PIXI;

const Screen: React.FC = () => {
  const { data: slotConfig } = useQuery<IConfig>(slotConfigGql);
  const { isSoundOn } = slotConfig!;
  const isProceedToGame = useReactiveVar(setIsProceedToGame);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    pixiContainerRef.current?.appendChild(Flow.the.application.view);
    Flow.the.init();
  }, []);

  return (
    <>
      <div className={styles['main']!} ref={pixiContainerRef} />
      {isProceedToGame && (
        <>
          <Events />
          <AutoPlaySettingsMenu />
          <InfoPopup />
          <Menu />
          <Spin />
        </>
      )}
    </>
  );
};

export default Screen;
