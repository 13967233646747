import { States } from '../config';
import { Flow } from '../index';
import { State } from './State';

export class Idle extends State {
  public name: States = States.IDLE;

  public nodes: Map<States, State> = new Map();

  public static the = new Idle();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Flow.the.controller.enterIdleState(prevState);
  }

  public exitState(nextState: States): void {
    Flow.the.controller.exitIdleState(nextState);
  }
}
