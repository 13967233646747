import type { TAudioSprite } from '@money.energy/audio-api/dist/d';

export enum ISongs {
  Ambiance = 'Ambiance',
  Background = 'Background',
  BigWin_Loop = 'BigWin_Loop',
  BigWin_End = 'BigWin_End',
  BigWin = 'BigWin',
  MegaWin = 'MegaWin',
  SmallWin = 'SmallWin',
  MediumWin = 'MediumWin',
  HighWin = 'HighWin',
  CountUp_Stop = 'CountUp_Stop',
  CountUp_Loop = 'CountUp_Loop',
  UI_AutoSpin = 'UI_AutoSpin',
  UI_BetChange = 'UI_BetChange',
  UI_ButtonClose = 'UI_ButtonClose',
  UI_ButtonPress = 'UI_ButtonPress',
  UI_ButtonHover = 'UI_ButtonHover',
  UI_MenuOpen = 'UI_MenuOpen',
  UI_SpinStart = 'UI_SpinStart',
  UI_SpinStop = 'UI_SpinStop',
  ScatterAppear_1 = 'ScatterAppear_1',
  ScatterAppear_2 = 'ScatterAppear_2',
  ScatterAppear_3 = 'ScatterAppear_3',
  ScatterAppear_4 = 'ScatterAppear_4',
  ScatterAppear_5 = 'ScatterAppear_5',
  ScatterWin = 'ScatterWin',
  UltraWin = 'UltraWin',
  SuperWin = 'SuperWin',
  FreeSpinClose = 'FreeSpinClose',
  FreeSpinEnd = 'FreeSpinEnd',
  FreeSpinStart = 'FreeSpinStart',
  FreeSpinsBackground = 'FreeSpinsBackground',
}

export const audioSprite: TAudioSprite = {
  [ISongs.Ambiance]: [0, 34811.06575963719, true],
  [ISongs.Background]: [36000, 66002.72108843538, true],
  [ISongs.BigWin]: [104000, 4662.857142857149],
  [ISongs.BigWin_End]: [110000, 5757.097505668938],
  [ISongs.BigWin_Loop]: [117000, 32002.90249433107, true],
  [ISongs.CountUp_Loop]: [151000, 449.8866213151871, true],
  [ISongs.CountUp_Stop]: [153000, 2639.8185941042984],
  [ISongs.FreeSpinClose]: [157000, 2970.702947845808],
  [ISongs.FreeSpinEnd]: [161000, 11207.981859410438],
  [ISongs.FreeSpinStart]: [174000, 11193.469387755102],
  [ISongs.FreeSpinsBackground]: [187000, 76802.90249433108, true],
  [ISongs.HighWin]: [265000, 4964.716553287985],
  [ISongs.MediumWin]: [271000, 4961.8140589569175],
  [ISongs.MegaWin]: [277000, 3307.3922902494246],
  [ISongs.ScatterAppear_1]: [282000, 6865.850340136035],
  [ISongs.ScatterAppear_2]: [290000, 6860.0453514739],
  [ISongs.ScatterAppear_3]: [298000, 6874.557823129237],
  [ISongs.ScatterAppear_4]: [306000, 6880.362811791372],
  [ISongs.ScatterAppear_5]: [314000, 6865.850340136035],
  [ISongs.ScatterWin]: [322000, 6874.557823129237],
  [ISongs.SmallWin]: [330000, 3606.3492063491935],
  [ISongs.SuperWin]: [335000, 3002.630385487521],
  [ISongs.UI_AutoSpin]: [340000, 253.96825396825307],
  [ISongs.UI_BetChange]: [342000, 105.94104308387386],
  [ISongs.UI_ButtonClose]: [344000, 219.13832199544458],
  [ISongs.UI_ButtonHover]: [346000, 114.64852607707599],
  [ISongs.UI_ButtonPress]: [348000, 103.03854875280649],
  [ISongs.UI_MenuOpen]: [350000, 129.16099773241285],
  [ISongs.UI_SpinStart]: [352000, 146.5759637188171],
  [ISongs.UI_SpinStop]: [354000, 85.6235827664591],
  [ISongs.UltraWin]: [356000, 4360.997732426313],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.Ambiance]: 0.4,
  [ISongs.Background]: 0.4,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.BigWin]: 0.6,
  [ISongs.MegaWin]: 0.6,
  [ISongs.SmallWin]: 0.6,
  [ISongs.MediumWin]: 0.6,
  [ISongs.HighWin]: 0.6,
  [ISongs.CountUp_Stop]: 0.3,
  [ISongs.CountUp_Loop]: 0.4,
  [ISongs.UI_AutoSpin]: 0.4,
  [ISongs.UI_BetChange]: 0.4,
  [ISongs.UI_ButtonClose]: 0.4,
  [ISongs.UI_ButtonPress]: 0.4,
  [ISongs.UI_ButtonHover]: 0.3,
  [ISongs.UI_MenuOpen]: 0.4,
  [ISongs.UI_SpinStart]: 0.2,
  [ISongs.UI_SpinStop]: 0.2,
  [ISongs.ScatterAppear_1]: 0.2,
  [ISongs.ScatterAppear_2]: 0.2,
  [ISongs.ScatterAppear_3]: 0.2,
  [ISongs.ScatterAppear_4]: 0.2,
  [ISongs.ScatterAppear_5]: 0.2,
  [ISongs.ScatterWin]: 0.6,
  [ISongs.UltraWin]: 0.6,
  [ISongs.SuperWin]: 0.6,
  [ISongs.FreeSpinClose]: 0.4,
  [ISongs.FreeSpinEnd]: 0.4,
  [ISongs.FreeSpinStart]: 0.4,
  [ISongs.FreeSpinsBackground]: 0.4,
};
