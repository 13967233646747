import AudioApi from '@money.energy/audio-api';
import { EventTypes } from '../../global.d';
import { setIsSoundOn } from '../../gql/cache';
import { calcPercentage, isMobileLandscape, isMobilePortrait, isTabletLandscape, isTabletPortrait } from '../../utils';
import {
  BTN_GAP_DESKTOP_H,
  BTN_GAP_DESKTOP_W,
  BTN_GAP_MOBILE_H,
  BTN_GAP_MOBILE_W,
  BTN_GAP_TABLET_H,
  BTN_GAP_TABLET_W,
  BTN_UI_DESKTOP_H,
  BTN_UI_DESKTOP_W,
  BTN_UI_MOBILE_H,
  BTN_UI_MOBILE_W,
  BTN_UI_TABLET_H,
  BTN_UI_TABLET_W,
  eventEmitter,
} from '../config';
import { UiButton } from '../ui/uiButton';

class SoundButton extends UiButton {
  constructor() {
    super(setIsSoundOn() ? 'sound-on' : 'sound-off');
    this.addChild(this.btn);
    this.interactive = true;
    this.initEvents();
    this.initSubscriptions();

    if (!AudioApi.isInitialized) {
      this.setDisable(true);
    }
  }

  private initEvents(): void {
    this.btn.on('click', () => this.handleClick());
    this.btn.on('touchstart', () => this.handleClick());
  }

  private initSubscriptions(): void {
    eventEmitter.on(EventTypes.TOGGLE_SOUND, (isSoundOn: boolean) => {
      this.updateIntent(isSoundOn ? 'sound-on' : 'sound-off');
    });

    eventEmitter.on(EventTypes.SOUND_INITIALIZED, () => {
      this.setDisable(false);
    });
  }

  private handleClick(): void {
    const nextState = !setIsSoundOn();
    AudioApi.unSuspend();
    AudioApi.setSoundState(nextState);
    setIsSoundOn(nextState);

    this.updateIntent(nextState ? 'sound-on' : 'sound-off');
  }

  public override handlePosition(): void {
    this.btn.anchor.set(0, 0);
    const ratio = this.applicationSize.width / this.applicationSize.height > 2.15;
    let deviceSize = 0;
    let btnSize = ratio ? BTN_UI_DESKTOP_H : BTN_UI_DESKTOP_W;
    let btnGap = ratio ? BTN_GAP_DESKTOP_H : BTN_GAP_DESKTOP_W;

    if (isTabletLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_TABLET_H : BTN_UI_TABLET_W;
      btnGap = ratio ? BTN_GAP_TABLET_H : BTN_GAP_TABLET_W;
    }
    if (isTabletPortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_TABLET_W;
      btnGap = BTN_GAP_TABLET_W;
    }
    if (isMobileLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_MOBILE_H : BTN_UI_MOBILE_W;
      btnGap = ratio ? BTN_GAP_MOBILE_H : BTN_GAP_MOBILE_W;
    }
    if (isMobilePortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_MOBILE_W;
      btnGap = BTN_GAP_MOBILE_W;
    }

    if (ratio) {
      deviceSize = this.applicationSize.height;
    } else {
      deviceSize = this.applicationSize.width;
    }

    if (this.isPortraitMode) {
      deviceSize = this.applicationSize.height;
    }

    const size = calcPercentage(deviceSize, btnSize);
    const gap = calcPercentage(deviceSize, btnGap);
    const x = gap;
    const y = gap;
    this.setSize(size);

    this.x = x;
    this.y = y;
  }
}

export default SoundButton;
