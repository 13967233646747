import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Texture } from 'pixi.js';
import { GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { AbstractContainer } from '../components/AbstractContainer';

enum BackgroundAnimation {
  Base = 'background_ambient',
  FreeSpins = 'idle',
}

class Background extends AbstractContainer {
  private baseBg = Texture.from(ResourceTypes.slotBg);

  private freeSpinsBg = Texture.from(ResourceTypes.slotBgFs);

  private bgSprite = new PIXI.Sprite();

  private ambientBaseAnimation: Spine | null = null;

  private ambientFsAnimation: Spine | null = null;

  constructor() {
    super();
    this.bgSprite.texture = setGameMode() === GameMode.FREE_SPINS ? this.freeSpinsBg : this.baseBg;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    this.initAnimation();
  }

  protected override onGameModeChange(settings: { mode: GameMode }): void {
    this.changeBg(settings.mode);
  }

  private changeBg(mode: GameMode): void {
    if (mode === GameMode.FREE_SPINS) {
      this.bgSprite.texture = this.freeSpinsBg;
      this.ambientBaseAnimation!.visible = false;
      this.ambientFsAnimation!.visible = true;
      this.ambientFsAnimation!.state.setAnimation(0, BackgroundAnimation.FreeSpins, true);
    }
    if (mode === GameMode.BASE_GAME) {
      this.bgSprite.texture = this.baseBg;
      this.ambientBaseAnimation!.visible = true;
      this.ambientFsAnimation!.visible = false;
      this.ambientBaseAnimation!.state.setAnimation(0, BackgroundAnimation.Base, true);
    }
  }

  private initAnimation(): void {
    this.ambientBaseAnimation = new Spine(PIXI.Loader.shared.resources['ambient']!.spineData!);
    this.ambientBaseAnimation.y = -10;
    this.ambientFsAnimation = new Spine(PIXI.Loader.shared.resources['fsAmbient']!.spineData!);

    this.addChild(this.ambientBaseAnimation, this.ambientFsAnimation);
    if (setGameMode() === GameMode.BASE_GAME) {
      this.ambientFsAnimation.visible = false;
      this.ambientBaseAnimation.visible = true;
      this.ambientBaseAnimation.state.setAnimation(0, BackgroundAnimation.Base, true);
    }
    if (setGameMode() === GameMode.FREE_SPINS) {
      this.ambientFsAnimation.visible = true;
      this.ambientBaseAnimation.visible = false;
      this.ambientBaseAnimation.state.setAnimation(0, BackgroundAnimation.FreeSpins, true);
    }
  }

  public override resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;
    this.appWidth = width;
    this.appHeight = height;
    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;
