import { ITextStyle } from 'pixi.js';

const fontFamily = 'Noto Sans';

export const textYouWon: Partial<ITextStyle> = {
  align: 'center',
  fill: '#FFE8C0',
  fontFamily,
  fontSize: 125,
  fontWeight: '900',
  miterLimit: 48,
  stroke: '#5C3557',
  strokeThickness: 25,
  whiteSpace: 'normal',
  lineJoin: 'round',
};

export const congratulationTextStyle: Partial<ITextStyle> = {
  align: 'center',
  fill: '#FFFFFF',
  fontFamily,
  fontSize: 70,
  fontWeight: '900',
  miterLimit: 48,
  stroke: '#5C3557',
  strokeThickness: 25,
  whiteSpace: 'normal',
  lineJoin: 'round',
};

export const titleTextStyle: Partial<ITextStyle> = {
  fill: '#FFE8C0',
  fontFamily,
  fontSize: 120,
  lineJoin: 'round',
  stroke: '#5C3557',
  strokeThickness: 25,
  whiteSpace: 'normal',
  fontWeight: '900',
};

export const outerFsAmountStyle: Partial<ITextStyle> = {
  align: 'center',
  fill: 'white', // Main fill color
  fontFamily,
  fontSize: 300,
  stroke: '#FFE8C0', // Outer stroke color
  strokeThickness: 60, // Set larger thickness for outer stroke
  fontWeight: '900',
  lineJoin: 'round', // Make the stroke edges rounded
  miterLimit: 2, // Control the sharpness of the corners; lower values make it more rounded
};

export const innerFsAmountStyle: Partial<ITextStyle> = {
  align: 'center',
  fill: 'white',
  fontFamily,
  fontSize: 300,
  stroke: '#5C3557', // Inner stroke color
  strokeThickness: 30, // Set smaller thickness for inner stroke
  fontWeight: '900',
  dropShadowBlur: 27,
  dropShadowColor: '#FFE8C0',
  dropShadowDistance: 0,
  lineJoin: 'round', // Make the stroke edges rounded
  miterLimit: 2, // Control the sharpness of the corners; lower values make it more rounded
};

export const textWinAmountInnerStyle: Partial<ITextStyle> = {
  fill: ['#FFF6A4', '#FFE388', '#FF9900', '#FFEE54', '#FFB240'],
  fillGradientStops: [0.6],
  fontFamily,
  fontSize: 150,
  lineJoin: 'round',
  miterLimit: 110,
  stroke: '#464646',
  strokeThickness: 25,
  whiteSpace: 'normal',
  fontWeight: '900',
};

export const textWinAmountOuterStyle: Partial<ITextStyle> = {
  fill: ['#FFF6A4', '#FFE388', '#FF9900', '#FFEE54', '#FFB240'],
  fillGradientStops: [0.6],
  fontFamily,
  fontSize: 150,
  lineJoin: 'round',
  miterLimit: 110,
  stroke: '#ffffff',
  strokeThickness: 50,
  whiteSpace: 'normal',
  fontWeight: '900',
};
