import type React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18next';
import styles from './info.module.scss';

const BuyBonus: React.FC = () => {
  const { t } = useTranslation();
  const features = [
    { key: 'infoBuyBonus1_1', text: i18n.t('infoBuyBonus1_1') },
    { key: 'infoBuyBonus1_2', text: i18n.t('infoBuyBonus1_2') },
    { key: 'infoBuyBonus1_3', text: i18n.t('infoBuyBonus1_3') },
  ];

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoBuyBonusTitle')}</h1>
      <div className={styles['row']}>
        {features.map((v) => (
          <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
        ))}
      </div>
    </div>
  );
};

export default BuyBonus;
