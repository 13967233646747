import type { Spine } from 'pixi-spine';
import type { Container } from 'pixi.js';
import type { SlotId } from '../config';
import type { BonusState, ReelSet } from '../global.d';
import type { AbstractContainer } from './components/AbstractContainer';
import { IReels } from './reels/d';
import type CountUp from './winAnimations/countUpAnimation';

export interface IGame {
  transparent: boolean;
  width: number;
  height: number;
}

export interface IPlayerSettingsMinMax {
  min: number;
  max: number;
}

export interface IPlayerSettings {
  betAmount: IPlayerSettingsMinMax;
  coinValue: {
    EUR: IPlayerSettingsMinMax;
    FUN: IPlayerSettingsMinMax;
  };
}

export interface ISlotData {
  id: string;
  lines: number[][];
  lineSets: LineSet[];
  clientSettings: {
    coinAmounts: {
      default: number[];
      quick: number[];
    };
    coinValues: {
      code: string;
      variants: number[];
    }[];
    features: Features[];
    autoplay: {
      options: number[];
      conditions: {
        stopOnAnyWin: {
          enabled: boolean;
        };
        stopIfFeatureIsWon: {
          enabled: boolean;
        };
        stopIfSingleWinExceeds: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceDecreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceIncreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
      };
    };
  };
  icons: Icon[];
  previewImage: string;
  reels: ReelSet[];
  settings: {
    betLines: {
      min: number;
      max: number;
    };
    startPosition: number[];
    playerSettings: IPlayerSettings;
  };
}

export interface Features {
  id: string;
  enabled: boolean;
}

export interface LineSet {
  id: string;
  slotId: string;
  lines: number[];
  coinAmountMultiplier: number;
}

export interface IWinLine {
  lineId: number;
  amount: number;
  winPositions: number[];
}

export interface IGameContainer {
  symbolAnimation: Container;
  reelsBackgroundContainer: Container;
  reelsContainer: IReels & AbstractContainer;
  miniPayInfoContainer: Container;
  countUp: CountUp;
  tintContainer: Container;
}

export type Combos = IconCombo[] | undefined;

export interface Icon {
  combos?: Combo[];
  id: SlotId;
  payoffType: PayoffType;
  type: IconType;
  changeTo?: SlotId;
  matrixIndex?: number;
  mysteryType?: MysteryType;
  columnId?: number;
}

export enum IconType {
  EMPTY = 'EMPTY',
  REGULAR = 'REGULAR',
  SCATTER = 'SCATTER',
  WILD = 'WILD',
}

export enum MysteryType {
  GOLDEN = 'GOLDEN',
  FREE_SPINS = 'FREE_SPINS',
  DEFAULT = 'DEFAULT',
}

export enum MysteryAnimations {
  GoldNormal = 'gold_normal_symbol_open',
  GoldSpecial = 'gold_special_symbol_open',
  BrownNormal = 'brown_normal_symbol_open',
  BlueNormal = 'blue_normal_symbol_open',
  BlueSpecial = 'blue_special_symbol_open',
}

export type MysteryMatrix = {
  changeTo: SlotId;
  mysteryType: MysteryType;
};

export enum PayoffType {
  LTR = 'LTR',
  RTL = 'RTL',
  ANY = 'ANY',
  ADJACENT = 'ADJACENT',
}
export enum RewardType {
  COINS = 'COINS',
  BONUS = 'BONUS',
}
export interface Reward {
  bonusId: string | null;
  count: number | null;
  multiplier: number | null;
  type: RewardType;
}
export interface IconCombo {
  pattern: string;
  multiplier: number;
}
export interface Combo {
  rewards: Reward[];
  pattern: string;
  payoffType: string;
  type: string;
}
export interface AnimatedPopupProps {
  spine: Spine;
  inAnimationName: string;
  idleAnimationName: string;
  endAnimationName: string;
}

export interface BuyBonusConfirmProps {
  totalCost: string;
  coinAmount: number;
  bonusState: BonusState;
}
export interface AnimatedPopupProps {
  spine: Spine;
  inAnimationName: string;
  idleAnimationName: string;
  endAnimationName: string;
}
export type PopupProps = BuyBonusConfirmProps | '';
