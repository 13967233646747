import { eventEmitter } from '../../game/config';
import { PopupModule } from '../../game/popups/PopupModule';
import { BuyBonusEnterProps, EventTypes, GameMode, UserBonus } from '../../global.d';
import { setCoinAmount, setCoinValue, setCurrentBonusId, setIsPopupOpened } from '../../gql/cache';
import client from '../../gql/client';
import { buyBonusGql } from '../../gql/mutation';
import type { States } from '../config';
import { Flow } from '../index';
import { RegularModule } from './RegularModule';

export class BuyBonusModule extends RegularModule {
  public override gameMode = GameMode.BUY_BONUS;

  public bonusId: string | null = null;

  public static override the = new BuyBonusModule();

  private constructor() {
    super();
  }

  override enterModule(prevGameMode: GameMode, props: BuyBonusEnterProps): void {
    if (prevGameMode !== GameMode.BASE_GAME) throw new Error('CANT BUY BONUS NOT IN BASE MODE');
    client
      .mutate<{
        buyBonus: {
          bonus: UserBonus;
          bonusId: string;
          id: string;
          balance: { amount: number; currency: string };
        };
      }>({
        mutation: buyBonusGql,
        variables: {
          input: {
            id: props?.bonusId,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      })
      .then((res) => {
        const { id, balance } = res.data!.buyBonus;
        eventEmitter.emit(EventTypes.DISABLE_PAYINFO);
        eventEmitter.emit(EventTypes.UPDATE_USER_BALANCE, balance);
        PopupModule.the.closeCurrentPopup();
        setIsPopupOpened(false);
        setCurrentBonusId(id);
        Flow.the.changeGameMode(GameMode.BASE_GAME);
        eventEmitter.emit(EventTypes.HANDLE_BUY_BONUS_COMPLETE);
        eventEmitter.emit(EventTypes.TOGGLE_SPIN);
      });
  }

  public override exitModule(_nextGameMode: GameMode): void {
    // AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
    // AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
  }

  public override enterIdleState(_prevState: States): void {
    eventEmitter.emit(EventTypes.TOGGLE_SPIN);
  }
}
