import { EventTypes } from '../../global.d';
import { setIsPopupOpened } from '../../gql/cache';
import { eventEmitter, PopupTypes } from '../config';
import type { Popup } from './popup';

export class PopupModule {
  public currentPopup: Popup | null = null;

  public popups: Map<PopupTypes, Popup>;

  public static the: PopupModule = new PopupModule();

  private constructor() {
    this.popups = new Map<PopupTypes, Popup>();
  }

  public registerPopup(type: PopupTypes, popup: Popup): void {
    this.popups.set(type, popup);
  }

  public openPopup(popupType: PopupTypes): void {
    if (this.currentPopup) {
      this.currentPopup.hide();
      this.currentPopup = null;
    }
    setIsPopupOpened(true);
    eventEmitter.emit(EventTypes.OPEN_POPUP, popupType);
    // eventEmitter.emit(EventTypes.OPEN_POPUP_BG);
    this.currentPopup = this.popups.get(popupType) || null;
    this.popups.get(popupType)?.show();
  }

  public closeCurrentPopup(): void {
    if (this.currentPopup) {
      this.currentPopup.hide();
      this.currentPopup = null;
    }
    setIsPopupOpened(false);
    eventEmitter.emit(EventTypes.CLOSE_POPUP);
    eventEmitter.emit(EventTypes.HIDE_CONTROL_BUTTON_POPUP);
  }
}
