import { States } from '../config';
import { Flow } from '../index';
import { State } from './State';

export class AfterWin extends State {
  public name: States = States.AFTER_WIN;

  public nodes: Map<States, State> = new Map();

  public static the = new AfterWin();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Flow.the.controller.enterAfterWinState(prevState);
  }

  public exitState(nextState: States): void {
    Flow.the.controller.exitAfterWinState(nextState);
  }
}
