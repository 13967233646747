import { States } from '../config';
import { Flow } from '../index';
import { State } from './State';

export class Mystery extends State {
  public name: States = States.MYSTERY;

  public nodes: Map<States, State> = new Map();

  public static the = new Mystery();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Flow.the.controller.enterMysteryState(prevState);
  }

  public exitState(nextState: States): void {
    Flow.the.controller.exitMysteryState(nextState);
  }
}
