import { EventTypes } from '../../global.d';
import { setIsTurboSpin } from '../../gql/cache';
import { calcPercentage, isMobileLandscape, isMobilePortrait, isTabletLandscape, isTabletPortrait } from '../../utils';
import {
  BTN_GAP_DESKTOP_H,
  BTN_GAP_DESKTOP_W,
  BTN_GAP_MOBILE_H,
  BTN_GAP_MOBILE_W,
  BTN_GAP_TABLET_H,
  BTN_GAP_TABLET_W,
  BTN_UI_DESKTOP_H,
  BTN_UI_DESKTOP_W,
  BTN_UI_MOBILE_H,
  BTN_UI_MOBILE_W,
  BTN_UI_TABLET_H,
  BTN_UI_TABLET_W,
  eventEmitter,
} from '../config';
import { UiButton } from '../ui/uiButton';

class TurboButton extends UiButton {
  constructor() {
    super(setIsTurboSpin() ? 'turbospin-on' : 'turbospin-off');
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
  }

  private initSubscriptions = (): void => {
    eventEmitter.on(EventTypes.TOGGLE_TURBO_SPIN, (_isTurboSpin: boolean) => {
      this.handleUpdateIntent();
    });
  };

  private handleClick = (): void => {
    setIsTurboSpin(!setIsTurboSpin());
    this.handleUpdateIntent();
  };

  private handleUpdateIntent(): void {
    if (setIsTurboSpin()) {
      this.updateIntent('turbospin-on');
    } else {
      this.updateIntent('turbospin-off');
    }
  }

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0);
    const ratio = this.applicationSize.width / this.applicationSize.height > 2.15;
    let deviceSize = 0;
    let btnSize = ratio ? BTN_UI_DESKTOP_H : BTN_UI_DESKTOP_W;
    let btnGap = ratio ? BTN_GAP_DESKTOP_H : BTN_GAP_DESKTOP_W;

    if (isTabletLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_TABLET_H : BTN_UI_TABLET_W;
      btnGap = ratio ? BTN_GAP_TABLET_H : BTN_GAP_TABLET_W;
    }
    if (isTabletPortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_TABLET_W;
      btnGap = BTN_GAP_TABLET_W;
    }
    if (isMobileLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_MOBILE_H : BTN_UI_MOBILE_W;
      btnGap = ratio ? BTN_GAP_MOBILE_H : BTN_GAP_MOBILE_W;
    }
    if (isMobilePortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_MOBILE_W;
      btnGap = BTN_GAP_MOBILE_W;
    }

    if (ratio) {
      deviceSize = this.applicationSize.height;
    } else {
      deviceSize = this.applicationSize.width;
    }

    if (this.isPortraitMode) {
      deviceSize = this.applicationSize.height;
    }

    const size = calcPercentage(deviceSize, btnSize);
    const gap = calcPercentage(deviceSize, btnGap);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height / 2 + size + gap;
    this.setSize(size);

    if (this.isPortraitMode) {
      this.btn.anchor.set(1, 0.5);
      x = this.applicationSize.width / 2 - size - gap;
      y = this.applicationSize.height - size * 4 - gap;
    }

    this.x = x;
    this.y = y;
  }
}

export default TurboButton;
