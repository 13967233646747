import { BlurFilter } from '@pixi/filter-blur';
import type { Group } from '@pixi/layers';
import _ from 'lodash';
import AudioApi from '@money.energy/audio-api';
import { ISongs, SlotId } from '../../config';
import { Flow } from '../../flow';
import { EventTypes, GameMode } from '../../global.d';
import { setBetResult, setCurrentIsTurboSpin, setScatterSoundPlayed, setSlotConfig } from '../../gql/cache';
import { getBetResult, getSpinResult, normalizePosition } from '../../utils';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import { BaseAnimation } from '../animations/reel/baseAnimation';
import type { ReelAnimation } from '../animations/reel/reelAnimation';
import Tween from '../animations/tween';
import { AbstractContainer } from '../components/AbstractContainer';
import {
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  eventEmitter,
  REEL_WIDTH,
  ReelState,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
} from '../config';
import { IReel } from './d';
import Slot from './slot';

const scatterSounds = [
  ISongs.ScatterAppear_1,
  ISongs.ScatterAppear_2,
  ISongs.ScatterAppear_3,
  ISongs.ScatterAppear_4,
  ISongs.ScatterAppear_5,
];

export class Reel extends AbstractContainer implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public slots: Slot[] = [];

  public size: number;

  public isPlaySoundOnStop: boolean;

  public animation: ReelAnimation | null = null;

  public stopPosition: number;

  private isForceStopped = false;

  private blurFilter: BlurFilter;

  constructor(id: number, data: SlotId[], startPosition: number, slotGroup: Group) {
    super();
    this.id = id;
    this.data = data;
    this.size = this.data.length;
    this.state = ReelState.IDLE;
    this.isPlaySoundOnStop = true;
    this.sortableChildren = true;
    this.stopPosition = startPosition;
    this.blurFilter = new BlurFilter();
    this.blurFilter.blur = 2;
    this.blurFilter.quality = 2;
    this.width = SLOT_WIDTH;
    this.x = id * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
    this.y = -SLOT_HEIGHT;
    this.createSlots(slotGroup);
    eventEmitter.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventEmitter.addListener(EventTypes.FORCE_STOP_REELS, () => {
      this.isForceStopped = true;
    });
  }

  public changeReelData(data: SlotId[], slotGroup: Group, position: number): void {
    this.stopPosition = position;
    this.data = data;
    this.size = this.data.length;
    this.slots = [];
    this.removeChildren();
    this.createSlots(slotGroup);
  }

  public changeData(data: SlotId[], slotGroup: Group): void {
    this.data = data;
    this.size = this.data.length;
    this.slots = [];
    this.removeChildren();
    this.createSlots(slotGroup);
    this.toggleBlurSlots(true);
  }

  public createSlots(slotGroup: Group): void {
    // let matrixItem;
    // const mysteryMatrix = setLastBetResult().id ? setLastBetResult().data.features?.mystery?.mysteryMatrix : [];
    // const startIndex = this.stopPosition - 1;
    // let idx = this.id;
    // const acc = REELS_AMOUNT;
    for (let i = 0; i < this.data.length; i++) {
      // if (i >= startIndex && mysteryMatrix) {
      //   const matrixVal = mysteryMatrix[idx];
      //   idx += acc;
      //   if (matrixVal?.changeTo) {
      //     matrixItem = matrixVal;
      //   }
      // }
      const slotId = this.data[i as number] as SlotId;
      // const slot = new Slot(i, slotId, this.id, slotId === SlotId.MS1 ? matrixItem?.mysteryType : undefined);
      const slot = new Slot(i, slotId, this.id, undefined);
      // if (matrixItem && slotId === SlotId.MS1) {
      //   slot.changeTexture(matrixItem.changeTo);
      // }
      // matrixItem = undefined;
      this.slots.push(slot);
      slot.parentGroup = slotGroup;
      slot.y = this.getSlotY(slot);

      this.addChild(slot);
    }
  }

  public getSlotY(slot: Slot): number {
    let position = -this.stopPosition;
    while (position < 0) {
      position += this.data.length;
    }
    return SLOT_HEIGHT * (((position + slot.id + 2) % this.data.length) + 0.5);
  }

  protected override onGameModeChange(_settings: { mode: GameMode }): void {
    this.visible = true;
  }

  private onReelEnding(_previousState: ReelState, _newState: ReelState): void {
    this.toggleBlurSlots(false);
  }

  private onReelStop(): void {
    const spinResult = getSpinResult({
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
      reelSet: getBetResult(setBetResult()).bet.reelSet,
      icons: setSlotConfig().icons,
      mysteryMatrix: getBetResult(setBetResult()).bet.data.features?.mystery?.mysteryMatrix || [],
    });
    const reelResult = [spinResult[this.id], spinResult[this.id + 5], spinResult[this.id + 5 * 2]];

    this.isForceStopped = false;
    if (reelResult.some((value) => value?.id === SlotId.SC1)) {
      const scatterSoundId = setScatterSoundPlayed();
      // Scatter stop sound logic
      if (this.id < 3 || (this.id === 3 && scatterSoundId > 0) || (this.id === 4 && scatterSoundId > 1)) {
        AudioApi.play({ type: scatterSounds[scatterSoundId], stopPrev: true });
      } else {
        AudioApi.play({ type: ISongs.UI_SpinStop, stopPrev: true });
      }
      setScatterSoundPlayed(scatterSoundId + 1);
      if (this.id === 4) {
        setScatterSoundPlayed(0);
      }
    } else {
      AudioApi.play({ type: ISongs.UI_SpinStop, stopPrev: true });
    }
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    if (previousState === ReelState.APPEARING) {
      this.onReelStop();
    }
  }

  private onReelRolling(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStarting(_previousState: ReelState, _newState: ReelState): void {
    this.toggleBlurSlots(true);
  }

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.DISAPPEARING) {
      this.onReelRolling(previousState, ReelState.DISAPPEARING);
    }
    if (newState === ReelState.WAITING) {
      this.onReelStarting(previousState, ReelState.WAITING);
    }
    if (newState === ReelState.APPEARING) {
      this.onReelEnding(previousState, ReelState.APPEARING);
    }
  }

  public createSpinAnimation(): ReelAnimation {
    const onChange = () => {
      this.slots.forEach((slot) => {
        slot.y = this.getSlotY(slot);
      });
    };
    const isTurboSpin = setCurrentIsTurboSpin() && Flow.the.controller.gameMode === GameMode.BASE_GAME;

    const disappearingAnimation = new AnimationChain();
    disappearingAnimation.appendAnimation(Tween.createDelayAnimation(100 * this.id));
    const disappearingTarget = normalizePosition(this.size, this.stopPosition - SLOTS_PER_REEL_AMOUNT);
    const disappearingBegin = this.stopPosition;
    disappearingAnimation.appendAnimation(
      new Tween({
        object: this,
        property: TweenProperties.STOP_POSITION,
        propertyBeginValue: disappearingBegin,
        target: disappearingBegin - SLOTS_PER_REEL_AMOUNT,
        duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
        delay: (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
        easing: BASE_REEL_STARTING_FORMULA,
      }),
    );
    disappearingAnimation.addOnStart(() => {
      this.changeState(ReelState.DISAPPEARING);
    });
    disappearingAnimation.addOnChange(onChange);
    const speed = 25;
    const duration = 12000;
    const waitingTarget = disappearingTarget - (speed * duration) / 1000;
    const waitingAnimation = new Tween({
      object: this,
      property: TweenProperties.STOP_POSITION,
      propertyBeginValue: disappearingTarget,
      target: waitingTarget,
      duration,
    });
    waitingAnimation.addOnStart(() => {
      this.changeState(ReelState.WAITING);
    });
    waitingAnimation.addOnChange(onChange);
    this.animation = new BaseAnimation({
      disappearingAnimation,
      waitingAnimation,
    });
    return this.animation;
  }

  private toggleBlurSlots(enabled: boolean): void {
    this.slots.forEach((slot) => {
      if (slot.slotId !== SlotId.SC1) slot.toggleBlur(enabled);
    });
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.slot.tint = 0xffffff;
    });
    this.isForceStopped = false;
  }
}
