import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { GameMode } from '../../global.d';
import { AbstractContainer } from '../components/AbstractContainer';

enum LogoAnimation {
  Base = 'idle',
  Action = 'action',
}

class Logo extends AbstractContainer {
  public gameLogo: Spine;

  constructor() {
    super();
    this.gameLogo = new Spine(PIXI.Loader.shared.resources['gameLogo']!.spineData!);
    this.gameLogo.name = 'gameLogo';
    this.gameLogo.state.setAnimation(0, LogoAnimation.Base, true);
    this.zIndex = 10;
    this.gameLogo.scale.set(0.32);
    this.addChild(this.gameLogo);
    this.interactiveChildren = false;
  }

  protected override onGameModeChange(settings: { mode: GameMode }): void {
    if (settings.mode === GameMode.FREE_SPINS) {
      this.gameLogo.visible = false;
    } else {
      this.gameLogo.visible = true;
    }
  }

  override resize(_width: number, _height: number): void {
    this.gameLogo.x = 0;
    this.gameLogo.y = -500;
  }
}

export default Logo;
