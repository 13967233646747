import { setUserConfig } from '@money.energy/utils-fe';
import type { GameMode, PopupOpeningTypes } from '../global.d';
import { isMobileDevice } from '../utils';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameHistory,
  setGameMode,
  setIsAuthorized,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterBonus,
  setIsFreeSpinsWin,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsOpenMenuPopup,
  setIsPopupOpeningInProgress,
  setIsSlotBusy,
  setIsSoundOn,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTurboSpin,
  setProgress,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setWinAmount,
} from './cache';
import type { DataGQl, ISlotConfig, IStressful } from './d';

const typePolicies = {
  Query: {
    fields: {
      isAuthorized: {
        read(): boolean {
          return setIsAuthorized();
        },
      },
      progress: {
        read(): { status: number; wasLoaded?: boolean } {
          return setProgress();
        },
      },
      isSoundOn: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig('config', storeFieldName, setIsSoundOn());
        },
      },

      isSpinInProgress: {
        read(): boolean {
          return setIsSpinInProgress();
        },
      },
      isSlotBusy: {
        read(): boolean {
          return setIsSlotBusy();
        },
      },
      isMobile: {
        read(): boolean {
          return isMobileDevice();
        },
      },
      betAmount: {
        read(): number {
          return setBetAmount();
        },
      },
      winAmount: {
        read(): number {
          return setWinAmount();
        },
      },
      coinValue: {
        read(): number {
          return setCoinValue();
        },
      },
      coinAmount: {
        read(): number {
          return setCoinAmount();
        },
      },
      autoSpinsAmount: {
        read(): number {
          return setAutoSpinsAmount();
        },
      },
      isContinueAutoSpinsAfterFeature: {
        read(): boolean {
          return setIsContinueAutoSpinsAfterBonus();
        },
      },
      isStopOnAnyWin: {
        read(): boolean {
          return setIsStopOnAnyWin();
        },
      },
      isStopOnFeatureWin: {
        read(): boolean {
          return setIsStopOnFeatureWin();
        },
      },
      isStopOnWinExceeds: {
        read(): boolean {
          return setIsStopOnWinExceeds();
        },
      },
      isStopOnBalanceIncrease: {
        read(): boolean {
          return setIsStopOnBalanceIncrease();
        },
      },
      isStopOnBalanceDecrease: {
        read(): boolean {
          return setIsStopOnBalanceDecrease();
        },
      },
      isAutoSpins: {
        read(): boolean {
          return setIsAutoSpins();
        },
      },
      gameMode: {
        read(): GameMode {
          return setGameMode();
        },
      },
      autoSpinsLeft: {
        read(): number {
          return setAutoSpinsLeft();
        },
      },
      stopOnWinExceeds: {
        read(): number {
          return setStopOnWinExceeds();
        },
      },
      stopOnBalanceIncrease: {
        read(): number {
          return setStopOnBalanceIncrease();
        },
      },
      stopOnBalanceDecrease: {
        read(): number {
          return setStopOnBalanceDecrease();
        },
      },
      autoSpinsStartBalance: {
        read(): number {
          return setAutoSpinsStartBalance();
        },
      },
      slotConfig: {
        read(): ISlotConfig {
          return setSlotConfig();
        },
      },
      slotHistory: {
        read(): boolean[] {
          return setGameHistory();
        },
      },
      isTurboSpin: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig('config', storeFieldName, setIsTurboSpin());
        },
      },
      stressful: {
        read(): IStressful {
          return setStressful();
        },
      },
      bets: {
        keyArgs: false as const,
        merge: false,
      },
      isOpenMenuPopup: {
        read(): boolean {
          return setIsOpenMenuPopup();
        },
      },
      isOpenBetSettingsPopup: {
        read(): boolean {
          return setIsOpenBetSettingsPopup();
        },
      },
      isOpenAutoplayPopup: {
        read(): boolean {
          return setIsOpenAutoplayPopup();
        },
      },
      isOpenInfoPopup: {
        read(): boolean {
          return setIsOpenInfoPopup();
        },
      },
      isOpenHistoryPopup: {
        read(): boolean {
          return setIsOpenHistoryPopup();
        },
      },
      isPopupOpeningInProgress: {
        read(): PopupOpeningTypes {
          return setIsPopupOpeningInProgress();
        },
      },
      isFreeSpinsWin: {
        read(): boolean {
          return setIsFreeSpinsWin();
        },
      },
      currentFreeSpinsTotalWin: {
        read(): number {
          return setCurrentFreeSpinsTotalWin();
        },
      },
      freeSpinsTotalWin: {
        read(): number {
          return setFreeSpinsTotalWin();
        },
      },
    },
  },
};

export default typePolicies;
