import { Container } from 'pixi.js';
import { EventTypes, GameMode } from '../../global.d';
import { eventEmitter } from '../config';

export class AbstractContainer extends Container {
  [x: string]: unknown;
  constructor() {
    super();
    this.visible = true;
    eventEmitter.on(EventTypes.CHANGE_MODE, this.onGameModeChange.bind(this));
    eventEmitter.on(EventTypes.RESTORE_GAME, this.onRestoreGame.bind(this));
    eventEmitter.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  protected onGameModeChange(_settings: { mode: GameMode }): void {}

  protected onRestoreGame(): void {}

  protected resize(_width: number, _height: number): void {}
}
